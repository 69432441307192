import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

export const documentsQuery = graphql`
  {
    allContentfulDocument {
      nodes {
        title
        document {
          file {
            url
          }
        }
      }
    }
  }
`

const DocumentsPage = ({ data }) => (
  <Layout>
    <div className='page-header'>
      <div className='container'>
        <div className='sixteen columns'>
          <h1 className='page-title'>Dokumenty ke stažení</h1>
        </div>
      </div>
    </div>

    <div className='container main'>
      <ul className='arrow-list' style={{ textDecoration: 'underline' }}>
        {data.allContentfulDocument.nodes.map((document, index) => (
          <li key={index}>
            <a href={document?.document?.file?.url}>{document.title}</a>
          </li>
        ))}
      </ul>
    </div>
  </Layout>
)

export default DocumentsPage
